<!--<template>-->
<!--  <div class="block__subtitle mb-40">{{ title }}</div>-->
<!--  <VProfile-->
<!--    v-if="!isEdit"-->
<!--    :class="classNameProfile"-->
<!--    show-buttons-->
<!--    @onEdit="goEdit"-->
<!--    :is-disabled="isSend"-->
<!--    @onChangePassword="handlerClickPassword"-->
<!--  >-->
<!--    <VProfileInfo />-->
<!--  </VProfile>-->

<!--  <button-->
<!--    v-if="isApplicant"-->
<!--    class="btn btn-primary btn-primary&#45;&#45;padding btn-link-weight-500 btn-bg-yellow mb-60"-->
<!--    @click="openVModal"-->
<!--  >-->
<!--    Получить аккредитацию-->
<!--  </button>-->

<!--  <div class="block__tabs mb-40" v-if="!isEdit">-->
<!--    <VTabs-->
<!--      use-params-->
<!--      is-small-->
<!--      :items="securityProfile" />-->
<!--  </div>-->

<!--  <router-view/>-->
<!--</template>-->

<!--<script>-->
<!--import VProfile from '@/components/profile/VProfile'-->
<!--import VProfileInfo from '@/components/profile/VProfileInfo'-->
<!--import VTabs from '@/components/views/Tabs/VTabs'-->
<!--import { securityProfile } from '@/utils/tabs'-->
<!--import { computed, onBeforeMount, reactive, ref } from 'vue'-->
<!--import { goAccreditation } from '@/utils/goRoute'-->
<!--import { useRoute, useRouter } from 'vue-router'-->
<!--import { useStore } from 'vuex'-->
<!--import { resetPasswordForAdmin } from '@/http/userApi'-->

<!--export default {-->
<!--  props: {-->
<!--    title: {-->
<!--      type: String,-->
<!--      required: true-->
<!--    }-->
<!--  },-->
<!--  setup () {-->
<!--    const store = useStore()-->
<!--    const router = useRouter()-->
<!--    const route = useRoute()-->
<!--    const isSend = ref(false)-->

<!--    const goEdit = () => {-->
<!--      router.push({ name: 'security-profile-edit', params: { id: route.params.id } })-->
<!--    }-->

<!--    const user = reactive({})-->

<!--    const getUser = computed(() => {-->
<!--      return user.value-->
<!--    })-->

<!--    onBeforeMount(async () => {-->
<!--      await store.dispatch('user/setUser', route.params.id)-->
<!--      user.value = store.getters['user/getUser']-->
<!--    })-->

<!--    const isEdit = computed(() => {-->
<!--      return router.currentRoute.value.name.includes('edit')-->
<!--    })-->

<!--    const isApplicant = computed(() => {-->
<!--      return user.value?.roles?.[0]?.name === 'bidder'-->
<!--    })-->

<!--    const classNameProfile = computed(() => {-->
<!--      return isApplicant.value ? 'mb-30' : 'mb-60'-->
<!--    })-->

<!--    const handlerClickPassword = async () => {-->
<!--      try {-->
<!--        isSend.value = true-->
<!--        await resetPasswordForAdmin(route.params.id)-->
<!--        await store.dispatch('setAlert', {-->
<!--          type: 'success',-->
<!--          description: 'Письмо с новым паролём успешно отправлено пользователю на почту'-->
<!--        })-->
<!--      } finally {-->
<!--        isSend.value = false-->
<!--      }-->
<!--    }-->

<!--    return {-->
<!--      isSend,-->
<!--      isApplicant,-->
<!--      goEdit,-->
<!--      isEdit,-->
<!--      getUser,-->
<!--      securityProfile,-->
<!--      goAccreditation,-->
<!--      handlerClickPassword,-->
<!--      classNameProfile-->
<!--    }-->
<!--  },-->
<!--  components: {-->
<!--    VProfile,-->
<!--    VProfileInfo,-->
<!--    VTabs-->
<!--  }-->
<!--}-->
<!--</script>-->

<template>
  <VLink
    v-if="$route.params.id"
    @click="$router.back()" class="mb-20">
    Назад
  </VLink>
  <div class="block__subtitle mb-40">{{ title }}</div>
  <VProfile
    v-if="!isEdit"
    :class="classNameProfile"
    show-buttons
    @onEdit="goEdit"
    :is-disabled="isSend"
    :avatar="getAvatar"
    @onChangePassword="handlerClickPassword"
  >
    <VProfileInfo />
  </VProfile>

  <button
    v-if="isApplicant"
    class="btn btn-primary btn-primary--padding btn-link-weight-500 btn-bg-yellow mb-60"
    @click="openVModal"
  >
    Получить аккредитацию
  </button>

  <div class="block__tabs mb-40" v-if="!isEdit">
    <VTabs
      use-params
      is-small
      :items="securityProfile" />
  </div>

  <router-view/>

  <teleport to="body">
    <VModal
      v-if="showVModal"
      confirm
      confirm-question="Пройти аккредитацию?"
      confirm-btn-accept="Пройти"
      confirm-btn-decline="Отмена"
      @close="requestAccreditation"
    />
  </teleport>
</template>

<script>
import { computed, onBeforeMount, reactive, ref } from 'vue'

import { FormHandler } from '@/utils/use/formHandler'
import VProfile from '@/components/profile/VProfile'
import VProfileInfo from '@/components/profile/VProfileInfo'
import VTabs from '@/components/views/Tabs/VTabs'
import { securityProfile } from '@/utils/tabs'
import VLink from '@/components/ui/buttons/VLink'
import useVModal from '@/use/Base/useVModal'
import { goAccreditation } from '@/utils/goRoute'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import VModal from '@/components/ui/modal/VModal'
import { AuthController } from '@/controllers/AuthController'
import { AccreditationController } from '@/controllers/AccreditationController'

export default {
  props: {
    title: {
      type: String,
      required: true
    }
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const authController = new AuthController()
    const accreditationController = new AccreditationController()
    const isSend = ref(false)

    const goEdit = () => {
      router.push({ name: 'security-profile-edit', params: { id: route.params.id } })
    }

    const user = reactive({})

    const getUser = computed(() => {
      return user.value
    })

    onBeforeMount(async () => {
      await store.dispatch('user/setUser', route.params.id)
      user.value = store.getters['user/getUser']
      if (route.params.id) {
        await router.push({ name: 'security-profile-info', params: { id: route.params.id } })
      }
    })

    const isEdit = computed(() => {
      return router.currentRoute.value.name.includes('edit')
    })

    const isApplicant = computed(() => {
      return user.value?.roles?.[0]?.name === 'user' && !isEdit.value
    })

    const getAvatar = computed(() => {
      return store.getters['user/getUser']?.avatar?.[0][0].url
    })

    const classNameProfile = computed(() => {
      return isApplicant.value ? 'mb-30' : 'mb-60'
    })

    const changeIsChangePassword = async () => {
      await authController.changePasswordByAdmin(isSend.value, route.params.id)
    }

    const { showVModal, closeVModal, openVModal } = useVModal()

    const requestAccreditation = async (result) => {
      if (result) {
        await accreditationController.applicationAccreditation(route.params.id)
      }
      closeVModal()
    }

    const form = FormHandler(changeIsChangePassword)

    const handlerClickPassword = async () => {
      await form.handlerErrors()
    }

    return {
      showVModal,
      closeVModal,
      openVModal,
      requestAccreditation,
      getAvatar,
      handlerClickPassword,
      isApplicant,
      goEdit,
      isEdit,
      getUser,
      securityProfile,
      goAccreditation,
      changeIsChangePassword,
      classNameProfile,
      isSend
    }
  },
  components: {
    VModal,
    VProfile,
    VProfileInfo,
    VTabs,
    VLink
  }
}
</script>
